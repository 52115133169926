import React from "react";
import HeroSection from "./HeroSection";
import FeaturesSections from "./FeaturesSection";
import AboutSection from "./AboutSection";
import ModulesSection from "./ModulesSection";
import FooterSection from "./FooterSection";
import HeaderSection from "./HeaderSection";
import AdditionalSection from "./AdditionalSection";

export default function Main(){
    return(
        <>
            <HeroSection/>
            <FeaturesSections/>
            <AdditionalSection/>
<ModulesSection/>
<AboutSection/>

<FooterSection/>

        </>
    )
}