import {CheckIcon} from '@heroicons/react/20/solid'

const noticeFeatures = [
    'Kategorizacija objava (hitne, informativne, s povratnom \n' +
    'informacijom...)',
    'Odabir primatelja ili grupe primatelja',
    'Mogućnost spremanja predložaka',
    'mogućnost traženja reakcije primatelja',
]

const baseFeatures = [
    'Jednostavan unos podataka',
    'Odabir čitatelja',
    'Kategorizacija članaka',
    'Lako pretraživanje',
]

const vehiclesFeatures = [
    'Automatski podsjetnik na listu zaduženja',
    'Pregled nad voznim parkom tvrtke',
    'Rezervacija vozila',
    'Svi kontakti na jednom mjestu',
]

const projectsFeatures = [
    'Pregled nad radnim zadatcima i odrađenim poslovima',
    'Uvih u izvještaje i utrošene radne sate',
    'Manje praznog hoda za sve uključene u projekt',
    'Krupna slika nad situacijom za upravu',
]

const vacationFeatures = [
    'Uvid radnika u stanje odobrenih i iskorištenih dana godišnjeg odmora/slobodnih dana/plaćenog dopusta',
    'Jednostavno slanje zahtjeva za odobrenje',
    'Automatizirano izdavanje rješenja o odobrenom godišnjem',
    'Cjelovit pregled nad odsustvima radnika po danima',
]

const humanResourcesFeatures = [
    'Vrlo preglednu evidenciju o svim zaposlenicima',
    'Podsjetnike na istek bitnih dokumenata (radne dozvole, ugovore na određeno…)',
    'Podsjetnike na jubilarne nagrade, isplatu za djecu do 15 godina..',
    'Pomoć za izračun dana GO (socijalni uvjeti, djeca, šk. sprema)',
]

const workHoursFeatures = [
    'Mogućnost prijave radnog vremena i za zaposlenike na izdvojenim lokacijama',
    'Jednostavan pregled nad svim evidencijama ',
    'Mogućnost pripreme podataka za isplatu plaće',
    'Povezanost s modulima Godišnjih odmora i Službenih putovanja',
]

const travelFeatures = [
    'Davanje prethodne suglasnosti voditelja odjela',
    'Uvid zaposlenika u interna pravila vezana za putovanja',
    'Pomoć pri računanju tuzemnih i inozemnih dnevnica',
    'Podsjetnik na kašnjenje izvještaja',
]

export default function ModulesSection() {
    return (
        <div className="bg-white py-24 sm:py-32" id={"modules"}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl sm:text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Moduli</h2>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        Sustav se sastoji od 10 međusobno povezanih modula. Dodatno, izrađujemo “module po mjeri” koji
                        se
                        nadovezuju i povezuju ostalim modulima.
                    </p>
                </div>
                <div
                    className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                    <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                        <div
                            className="rounded-2xl  py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 notice-board lg:h-full md:h-96 sm:h-24">
                            <div className="mx-auto max-w-xs px-8">
                            </div>
                        </div>
                    </div>
                    <div className="p-8 sm:p-10 lg:flex-auto">
                        <h3 className="text-2xl font-bold tracking-tight text-gray-900">Oglasna ploča</h3>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Središnje mjesto obavještavanja unutar tvrtke. Da važne ili manje važne
                            informacije ne kolaju samo po hodnicima ili se zagube među mailovima.
                            Oglasna ploča je korisna stara praksa, ali u novom ruhu.

                        </p>
                        <div className="mt-10 flex items-center gap-x-4">
                            <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Što nudi</h4>
                            <div className="h-px flex-auto bg-gray-100"/>
                        </div>
                        <ul
                            role="list"
                            className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                        >
                            {noticeFeatures.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
                <div
                    className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                    <div className="p-8 sm:p-10 lg:flex-auto">
                        <h3 className="text-2xl font-bold tracking-tight text-gray-900">Baza znanja</h3>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Kroz godine stečeno znanje oko nekog procesa ili rješavanja
                            problema često se zadržava na razini jedne ili manjeg broja
                            osoba. U bazu znanja zapisuju se najbolje prakse i načini rješavanja
                            zadataka kako bi ostale zapamćene. Odlaskom osobe iz tvrtke, ne odlazi i prikupljeno znanje.
                        </p>
                        <div className="mt-10 flex items-center gap-x-4">
                            <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Što nudi</h4>
                            <div className="h-px flex-auto bg-gray-100"/>
                        </div>
                        <ul
                            role="list"
                            className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                        >
                            {baseFeatures.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                        <div
                            className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 knoweledge-base lg:h-full md:h-96 sm:h-24">
                            <div className="mx-auto max-w-xs px-8">
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                    <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                        <div
                            className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 vehicles lg:h-full md:h-96 sm:h-24">
                            <div className="mx-auto max-w-xs px-8">
                            </div>
                        </div>
                    </div>
                    <div className="p-8 sm:p-10 lg:flex-auto">
                        <h3 className="text-2xl font-bold tracking-tight text-gray-900">Službena vozila</h3>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Svako vozilo zahtijeva pažnju oko velikog broja elemenata. Od registracije,
                            osiguranja, zamjene guma, održavanja... Uredski pomoćnik pomaže pratiti
                            svaki detalj i podsjeća vas na nadolazeće aktivnosti.
                        </p>
                        <div className="mt-10 flex items-center gap-x-4">
                            <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Što nudi</h4>
                            <div className="h-px flex-auto bg-gray-100"/>
                        </div>
                        <ul
                            role="list"
                            className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                        >
                            {vehiclesFeatures.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
                <div
                    className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                    <div className="p-8 sm:p-10 lg:flex-auto">
                        <h3 className="text-2xl font-bold tracking-tight text-gray-900">Projekti</h3>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Znati tko je preuzeo ili nije koji zadatak s liste aktivnosti nije uvijek
                            jednostavno. Praćenje projekata, faza izvršenja, opterećenja zaposlenika
                            često je nepregledno i komplicirano. Uredski pomoćnik stvarno pomaže.
                        </p>
                        <div className="mt-10 flex items-center gap-x-4">
                            <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Što nudi</h4>
                            <div className="h-px flex-auto bg-gray-100"/>
                        </div>
                        <ul
                            role="list"
                            className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                        >
                            {projectsFeatures.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                        <div
                            className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 projects lg:h-full md:h-96 sm:h-24">
                            <div className="mx-auto max-w-xs px-8">
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                    <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                        <div
                            className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 vacation lg:h-full md:h-96 sm:h-24">
                            <div className="mx-auto max-w-xs px-8">
                            </div>
                        </div>
                    </div>

                    <div className="p-8 sm:p-10 lg:flex-auto">
                        <h3 className="text-2xl font-bold tracking-tight text-gray-900">Godišnji odmori</h3>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Jedna od najzanimljivijih informacija za svakog zaposlenika je broj
                            neiskorištenih dana godišnjeg odmora. Uvid u taj podatak, ali i sve
                            dodatne informacije nalaze se u ovom modulu
                        </p>
                        <div className="mt-10 flex items-center gap-x-4">
                            <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Što nudi</h4>
                            <div className="h-px flex-auto bg-gray-100"/>
                        </div>
                        <ul
                            role="list"
                            className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                        >
                            {vacationFeatures.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
                <div
                    className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                    <div className="p-8 sm:p-10 lg:flex-auto">
                        <h3 className="text-2xl font-bold tracking-tight text-gray-900">Kadrovska evidencija
                            zaposlenika</h3>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Središnje mjesto za sve informacije o zaposlenicima i suradnicima tvrtke.
                            Posebnost ovog modula sastoji se od mogućnosti postavljanja podsjetnika na istek ugovora,
                            certifikata, radnih dozvola i sl.

                        </p>
                        <div className="mt-10 flex items-center gap-x-4">
                            <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Što nudi</h4>
                            <div className="h-px flex-auto bg-gray-100"/>
                        </div>
                        <ul
                            role="list"
                            className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                        >
                            {humanResourcesFeatures.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                        <div
                            className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 humanResources lg:h-full md:h-96 sm:h-24">
                            <div className="mx-auto max-w-xs px-8">
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                    <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                        <div
                            className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 workingHours lg:h-full md:h-96 sm:h-24">
                            <div className="mx-auto max-w-xs px-8">
                            </div>
                        </div>
                    </div>

                    <div className="p-8 sm:p-10 lg:flex-auto">
                        <h3 className="text-2xl font-bold tracking-tight text-gray-900">Evidencija radnog vremena</h3>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                            Evidencija radnog vremena s detaljnim pregledom o evidentiranim radnim satima u određenom
                            vremenskom periodu i za zaposlenike i za upravu. Za tekući radni dan u realnom vremenu
                            prikazuje se aktivnost pojedinog zaposlenika (rad iz ureda, kuće, sastanak, pauza...).
                        </p>
                        <div className="mt-10 flex items-center gap-x-4">
                            <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Što nudi</h4>
                            <div className="h-px flex-auto bg-gray-100"/>
                        </div>
                        <ul
                            role="list"
                            className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                        >
                            {workHoursFeatures.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>

                </div>
                <div
                    className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                    <div className="p-8 sm:p-10 lg:flex-auto">
                        <h3 className="text-2xl font-bold tracking-tight text-gray-900">Putni nalozi</h3>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                           Kompletan proces od podnošenja zahtjeva zaposlenika za odobravanjem službenog putovanja do podnošenja i zaključenja izvještaja.

                        </p>
                        <div className="mt-10 flex items-center gap-x-4">
                            <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Što nudi</h4>
                            <div className="h-px flex-auto bg-gray-100"/>
                        </div>
                        <ul
                            role="list"
                            className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                        >
                            {travelFeatures.map((feature) => (
                                <li key={feature} className="flex gap-x-3">
                                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                                    {feature}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                        <div
                            className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 travel lg:h-full md:h-96 sm:h-24">
                            <div className="mx-auto max-w-xs px-8">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
