import {CloudArrowUpIcon, LockClosedIcon, ServerIcon} from '@heroicons/react/20/solid'
import noticeBoard from './../../img/noticeBoard.png'
import questions from "./../../img/Minimalist Beige Cream Brand Proposal Presentation.png"
import video from "./../../img/Video.mp4";

const features = [
    {
        name: 'Povećava',
        description:
            'produktivnost svake osobe',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'Svaka',
        description: 'infomacija, podsjetnik, bilješka i dokument je na svom mjestu',
        icon: LockClosedIcon,
    },
    {
        name: 'Omogućava',
        description: 'olakšan uvid upravi u poslovne i organizacijske procese',
        icon: ServerIcon,
    },
    {
        name: 'Daje',
        description: 'jasan pregled u kojoj su fazi poslovni procesi i koliko je \n' +
            'opterećenje po zaposlenicima',
        icon: ServerIcon,
    },
]

export default function FeaturesSections() {
    return (
        <div className="overflow-hidden bg-gray-900 py-24 sm:py-32"id={"officeHelper"}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div
                    className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <h2 className="text-base font-semibold leading-7 text-indigo-600">Najveća prednost:
                                Jednostavnost!</h2>
                            <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Uredski
                                pomoćnik je:</p>
                            <p className="mt-6 text-lg leading-8 text-white">
                                Središnje mjesto za sve informacije, dokumente i zbivanja unutar tvrtke
                                Koristi se kao početna stranica na računalu svakog zaposlenika
                                Predstavlja središnji kanal komunikacije – a ne isključuje već
                                uspostavljene kanale
                                <br/>
                                Objedinjuje sve važne i aktualne informacije na jednom mjestu
                                Omogućava komuniciranje samo prema odabranim osobama ili grupama
                                osoba (uz dodatak privatnih grupa)
                            </p>
                            <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-white lg:max-w-none">
                                {features.map((feature) => (
                                    <div key={feature.name} className="relative pl-9">
                                        <dt className="inline font-semibold text-white">
                                            <feature.icon className="absolute top-1 left-1 h-5 w-5 text-indigo-600"
                                                          aria-hidden="true"/>
                                            {feature.name}
                                        </dt>
                                        {' '}
                                        <dd className="inline">{feature.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                    <video src={video} loop muted autoPlay
                     className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        width={2432}
                        height={1442}

                    >
                </video>
                   {/* <img
                        src={questions}
                        alt="Product screenshot"
                        className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        width={2432}
                        height={1442}
                    />*/}
                </div>
            </div>
        </div>
    )
}
