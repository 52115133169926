/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import {CalendarDaysIcon, HandRaisedIcon, MapPinIcon, UserIcon} from '@heroicons/react/24/outline'

export default function FooterSection() {
    return (
        <div className="relative isolate overflow-hidden bg-gray-300 py-16 sm:py-24 lg:py-32" id={"contact"}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 lg:max-w-none lg:grid-cols-2">
                    <div className="max-w-xl lg:max-w-lg">
                        <h2 className="text-3xl font-bold tracking-tight text-black sm:text-4xl">Kontaktirajte nas!</h2>
                        <p className="mt-4 text-lg leading-8 text-black">
                            Dostupni smo za sva pitanja informacije i pomoć. Čekamo vaš poziv!
                        </p>
                    </div>
                    <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
                        <div className="flex flex-col items-start">
                            <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                                <MapPinIcon className="h-6 w-6 text-black" aria-hidden="true"/>
                            </div>
                            <dt className="mt-4 font-semibold text-black">IT Consulting Services d.o.o.</dt>
                            <dd className="mt-2 leading-7 text-black">
                                Stjepana Radića 18
                            </dd>
                            <dd className="mt-2 leading-7 text-black">
                               47 000 Karlovac, Hrvatska
                            </dd>
                        </div>
                        <div className="flex flex-col items-start">
                            <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                                <UserIcon className="h-6 w-6 text-black" aria-hidden="true"/>
                            </div>
                            <dt className="mt-4 font-semibold text-black">email: <a href="mailto:info@itcs.hr">info@itcs.hr</a></dt>
                            <dd className="mt-2 leading-7 text-gray-900">
                                tel: <a href="tel:+385911101507">+385 91 110 1507</a>
                            </dd>
                            <dd className="mt-2 leading-7 text-gray-900">
                                web: <a href="https://www.itcs.hr" target={"_blank"}>www.itcs.hr</a>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            <svg
                className="absolute top-0 left-1/2 -z-10 h-[42.375rem] -translate-x-1/2 blur-3xl xl:-top-6"
                viewBox="0 0 1155 678"
                fill="none"
            >
                <path
                    fill="url(#09dbde42-e95c-4b47-a4d6-0c523c2fca9a)"
                    fillOpacity=".3"
                    d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                />
                <defs>
                    <linearGradient
                        id="09dbde42-e95c-4b47-a4d6-0c523c2fca9a"
                        x1="1155.49"
                        x2="-78.208"
                        y1=".177"
                        y2="474.645"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#9089FC"/>
                        <stop offset={1} stopColor="#FF80B5"/>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    )
}
