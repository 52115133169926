import React from "react";
import logoLight from './../img/logo-white.png'
import cro from './../img/croatian.png'
import {Link} from "react-router-dom";
import jumbotron from './../img/jumbotron.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleDown, faArrowDown, faBars} from "@fortawesome/free-solid-svg-icons";
import design from './../img/web-design-itcs.png'
import development from './../img/web-app.png'
import ChristmasTree from "./ChristmasTree";


export default function En() {
    return (
        <div className={"main-div"}>

            <input type="checkbox" id="nav-toggler" style={{display: "none"}}/>
            <div className="nav-bar" id="navbar">
                <h1 className="web-title">
                    <div>
                        <div>
                            <span><img src={logoLight} width="80" alt=""/></span>
                        </div>
                    </div>
                </h1>
                <label htmlFor="nav-toggler" style={{display: "flex", alignItems: "center"}}>
                    <Link to={'/'}><img src={cro} width="50" alt=""/></Link>
                    <FontAwesomeIcon icon={faBars} style={{color: 'gold'}}/>
                </label>
            </div>
            <div className="side-bar" data-open="false">
                <ul className="navbar-options">
                    <li className="navbar-item"><a href="#design">Design</a></li>
                    <li className="navbar-item"><a href="#development">Development</a></li>
                    <li className="navbar-item"><a href="#consulting">Consulting</a></li>
                    <li className="navbar-item"><a href="/products/eng">Products</a></li>
                    <li className="navbar-item"><a href="#" data-toggle="modal" data-target="#exampleModal">Contact</a>
                    </li>
                    <li className="navbar-item">
                        <hr style={{border: 'none', borderBottom: '2px solid #E4D1B0'}}/>
                    </li>
                    <li className="navbar-item" id="company">
                        <h6>IT Consulting Services Ltd</h6>
                        <p>Stjepana Radića 18</p>
                        <p>HR-47000 Karlovac</p>
                        <p>Company is recorded in Sudski registar Trgovačkog suda in Zagreb (SS Karlovac). Company
                            identification
                            number is 060141866.</p>
                        <p>Capital: 36.259,87 euro, paid in full.</p>
                        <p>IBAN: HR2124840081101323324</p>
                        <p>Raiffeisenbank Austria d.d.</p>
                        <p>OIB: 42237961602</p>
                        <p>Board member: Branko Radojević</p>
                        <p>+385 91 128 1064</p>
                    </li>
                </ul>
            </div>

            <section className="front-main">

                <img src={jumbotron} alt="front-icon"/>

                <div className="main-header-details">
                    <p>Connecting your dots</p>
                    <h1>IT Consulting Services</h1>
                    <p>We are creating complex web applications and unique websites tailored to the needs of our
                        users.</p>
                    <p>We are providing professional consulting services in the field of ITC</p>
                    <p style={{color: 'gold'}}>True entrepreneurs deserve true partners!</p>
                    <div className="row">
                        <button className="btn btn-outline-warning ml-2" data-toggle="modal"
                                data-target="#exampleModal">CONTACT US
                        </button>
                    </div>
                </div>

                <div className="arrow-test">
                    <a href="#development">
                        <FontAwesomeIcon icon={faAngleDoubleDown} bounce style={{color: 'gold'}} className={'bounce'}/>
                    </a>
                </div>

            </section>

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">Contact us!</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5>IT Consulting Services</h5>
                            <a href="mailto:info@itcs.hr">Email: info@itcs.hr</a>
                            <br/>
                            <a href="phone:+385911281064">Phone: +385 91 128 1064</a>
                        </div>
                    </div>
                </div>
            </div>


            <div id="development" style={{marginBottom: '5%'}}></div>

            <div className="content-section-b">


                <div className="row content-b">
                    <div className="col-lg-5">


                        <h2 className="section-heading">1. WEB DEVELOPMENT</h2>
                        <hr className="section-heading-spacer"/>
                        <div className="clearfix"></div>

                        <p className="lead text-dark">
                            We are creating complex web applicaitons according to our users needs in order to
                            facilitate the
                            business of the user. Many years of experience in web development, as well as
                            high-quality team helps us
                            turn every idea into reality. In cooperation with our users, we achieve the best
                            solutions for business
                            process automation. We use backend language Pyton and it's framework Django for backend
                            development, and
                            React as frondend framework.
                        </p>

                        <a href="/products/eng">Take a look at some of our products</a>

                    </div>
                    <div className="col-lg-5 col-lg-offset-2 col-sm-6" align="center">

                        <div align="center">
                            <img className="img-responsive" src={development}
                                 alt=""/>
                        </div>
                    </div>
                </div>


            </div>


            <div className="content-section-d">

                <div id="design" style={{marginBottom: '5%'}}></div>
                <div className="row content-c">
                    <div className="col-lg-5 col-lg-offset-2 col-sm-6" align="center">
                        <div className="div" align="center">
                            <img className="phone"
                                 src={design} alt=""/>

                        </div>
                    </div>

                    <div className="col-lg-5">

                        <h2 className="section-heading-white text-dark">2. WEBSITE DESIGN AND DEVELOPMENT</h2>

                        <hr className="section-heading-spacer-white"/>
                        <div className="clearfix"></div>


                        <p className="lead">We are creating modern websites at the request of our users for all
                            three platforms
                            (smartphones, tablets and computers) not using CMS systems like Joomle or WordPress. Our
                            websites are
                            based on fronted and backend technologies which include HTML, CSS, JavaScript and React
                            as frontend
                            frameworks, and Django as backend framework. Working with these frameworks enables us to
                            adapt the
                            system to our users needs by creating personalised and unique systems which are not
                            limitet by the forms
                            dictated by CMS systems.

                        </p>
                        <p className="lead">For our clients, we provide services of website design and web
                            development.</p>
                        <p className="lead">Aditionally, we also provide domain registration, web hosting, content
                            creation,
                            maintenance, backups, and other necessary services for all of our websites and systems.
                        </p>
                        <p className="lead">
                            For more informations visit <a href="http://hw.stradun.net/index.html">hocu.website</a>.
                        </p>

                    </div>

                </div>


            </div>


            <div className="content-section-a">
                <div id="consulting" style={{marginBottom: '5%'}}></div>
                <div className="row content-a">
                    <div className="col-lg-12 col-lg-offset-1">

                        <div className="clearfix"></div>
                        <h2 className="section-heading">3. CONSULTING</h2>
                        <hr className="section-heading-spacer"/>
                        <div className="clearfix"></div>
                        <h4>Analysis and audit</h4>
                        <p className="lead">High-quality documentation is necessary for efficient resource
                            management, making a
                            foundation for any system upgrades.

                        </p>
                        <p className="lead">Our team of experts will help you establish the exsisting situation,
                            giving you advice about
                            establishing high-quality infrastructure which is essential in every successful
                            organization.

                        </p>
                        <h4 className="">Migrations
                        </h4>
                        <p className="lead">Correct assessment about investing in private equipment storage,
                            infrastructure, services,
                            or use of leased resources in a domestic country or abroad, without compromising system
                            security, is an
                            important component for the budget of any organization, therefore it needs to be
                            approached
                            professionally. We offer consulting services about system migration to leased space,
                            infrastructure,
                            cloud, or services.

                        </p>

                        <h4 className="">Procurement of IT equipment and services
                        </h4>
                        <p className="lead">A minor mistake with defining the procurement may result in several
                            months of delivery
                            delays and unnecessary additional investments. Consult on time, plan well.

                        </p>

                        <h4 className="">Supervision
                        </h4>
                        <p className="lead">
                            Make sure you get through delivers exactly what your contract demanded. Contact us with
                            confidence
                            because our advisors have many years of experience in such and similar jobs. If you are
                            not sure about
                            the services of migration and consolidation entrust the work to experienced consultants
                            who will
                            accompany you through all agreed phases of migration or/and consolidation of your
                            infrastructure and
                            information services.


                        </p>


                    </div>


                </div>
                b

            </div>
            <footer style={{backgroundColor: "black"}}>

                <div className="row">

                    <p className="copyright text-white small ml-5">Copyright &copy; IT Consulting Services d.o.o.</p>

                </div>

            </footer>

        </div>
    )
}