import {useState} from 'react'
import {Dialog} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import logo from './../../img/itcs-logo.png'
import video from "./../../img/571496_Businessman Working Office Happy_By_MayaLab_Artlist_HD.mp4"

const navigation = [
    {name: 'Uredski pomoćnik', href: '#officeHelper'},
    {name: 'Moduli', href: '#modules'},
    {name: 'O nama', href: '#about'},
    // {name: 'Vaučer', href: 'https://mingor.gov.hr/javni-pozivi-i-natjecaji-ministarstva/7389'},
]

export default function HeroSection() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div className="bg-white">
            <header className="absolute inset-x-0 top-0 z-50 nav-area">
                <nav className="flex items-center justify-between p-2 lg:px-4" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <a href="https://www.itcs.hr/" target={"_blank"} className="-m-1.5 p-1.5">
                            <img
                                className="h-12 w-auto"
                                src={logo}
                                alt=""
                            />
                        </a>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="hidden lg:flex lg:gap-x-12">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href}
                               className="text-sm font-semibold leading-6 text-white">
                                {item.name}
                            </a>
                        ))}
                    </div>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        <a href="#contact" className="text-sm font-semibold leading-6 text-white">
                            Kontakt
                        </a>
                    </div>
                </nav>
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                    <div className="fixed inset-0 z-50"/>
                    <Dialog.Panel
                        className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-300 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <a href="#" className="-m-1.5 p-1.5">
                                <img
                                    className="h-8 w-auto"
                                    src={logo}
                                    alt=""
                                />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true"/>
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {navigation.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-black hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    <a
                                        href="#contact"
                                        className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-black hover:bg-gray-50"
                                    >
                                        Kontakt
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>

            {/*<div className="bg-video-wrap">
                <video src={video} loop muted autoPlay>
                </video>
                <a href="https://mingor.gov.hr/javni-pozivi-i-natjecaji-ministarstva/7389" target={"_blank"}>
                    <div className="">
                        <div className="rubber_stamp">ISKORISTITE VAUČER
                            <br/>
                            ZA DIGITALIZACIJU
                        </div>
                        </div>

                </a>
                <div className="text-center-header">
                    <h1 className="text-4xl font-bold tracking-tight text-indigo-100 sm:text-6xl">
                        Uredski pomoćnik
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-white">
                        Interni web tvrtke namijenjen i vidljiv samo zaposlenicima tvrtke.
                        Jedinstven po JEDNOSTAVNOSTI KORIŠTENJA!
                        Pridonosi boljoj organizaciji svakodnevnog poslovanja svakog
                        zaposlenika.
                        Smanjuje potrebe za excel tablicama i zapisima po rokovnicima.
                        Nudi različit uvid u sadržaj, ovisno o hijerarhijskim ovlaštenjima
                        Svatko vidi samo ono što treba vidjeti.
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a
                            href="#modules"
                            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Moduli
                        </a>
                        <a href="#officeHelper" className="text-sm font-semibold leading-6 text-white">
                            Saznaj više <span aria-hidden="true">→</span>
                        </a>
                    </div>
                </div>

        </div>*/}

            <div className="relative isolate px-6 pt-14 lg:px-8 hero-section">
                <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
                    <svg
                        className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
                            fillOpacity=".3"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC"/>
                                <stop offset={1} stopColor="#FF80B5"/>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-40">
                    {/*<div className="rubber_stamp">ISKORISTITE VAUČER
                        <br/>
                        ZA DIGITALIZACIJU
                    </div>*/}
                    <div className="text-center hero-text">
                        <h1 className="text-4xl font-bold tracking-tight text-indigo-100 sm:text-6xl">
                            Uredski pomoćnik
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-indigo-100">
                            Interni web tvrtke namijenjen i vidljiv samo zaposlenicima tvrtke.
                            Jedinstven po JEDNOSTAVNOSTI KORIŠTENJA!
                            Pridonosi boljoj organizaciji svakodnevnog poslovanja svakog
                            zaposlenika.
                            Smanjuje potrebe za excel tablicama i zapisima po rokovnicima.
                            Nudi različit uvid u sadržaj, ovisno o hijerarhijskim ovlaštenjima
                            Svatko vidi samo ono što treba vidjeti.
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                            <a
                                href="#modules"
                                className="rounded-md bg-indigo-100 px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Moduli
                            </a>
                            <a href="#officeHelper" className="text-sm font-semibold leading-6 text-indigo-100">
                                Saznaj više <span aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                    <svg
                        className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                        viewBox="0 0 1155 678"
                    >
                        <path
                            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                            fillOpacity=".3"
                            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                        />
                        <defs>
                            <linearGradient
                                id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                                x1="1155.49"
                                x2="-78.208"
                                y1=".177"
                                y2="474.645"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stopColor="#9089FC"/>
                                <stop offset={1} stopColor="#FF80B5"/>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
            }
        </div>
    )
}