import React from "react";
import {Link} from "react-router-dom";


export default function Products() {
    return (
        <div>
            <nav class="site-header sticky-top py-1 bg-light ">
                <div class="container d-flex flex-column flex-md-row justify-content-between">
                    <a class="py-2" href="#">
                    </a>
                    <a class="py-2 d-none d-md-inline-block" href="/">Naslovna</a>
                    <a class="py-2 d-none d-md-inline-block" href="#inp">Uredski pomoćnik</a>
                    <a class="py-2 d-none d-md-inline-block" href="#constructis">Constructis</a>
                    <a class="py-2 d-none d-md-inline-block" href="#audio">Audio player</a>
                    <a class="py-2 d-none d-md-inline-block" href="#udruge">Udruge</a>
                </div>
            </nav>

            <div class="position-relative overflow-hidden text-center bg-light">
                <div class="col-md-5 p-lg-5 mx-auto my-5">
                    <h1 class="display-4 font-weight-normal">Proizvodi</h1>
                    <p class="lead font-weight-normal">Zavirite u svijet naših proizvoda te odaberite onaj koji je
                        prilagođen Vašim
                        potrebama.</p>
                    <a class="btn btn-outline-secondary" href="#inp">Saznaj više</a>
                </div>
                <div class="product-device shadow-sm d-none d-md-block"></div>
                <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
            </div>

            <div class="" >
                <div class="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden" id={"inp"}>
                    <div class="my-3 py-3">
                        <h2 class="display-5">Uredski pomoćnik</h2>
                        <p class="lead text-light text-left">

                            Interni sustav tvrtke namijenjen i vidljiv samo zaposlenicima tvrtke.
                            Omogućava olakšan uvid upravi u poslovne i organizacijske procese te
                            nudi različit uvid u sadržaj, ovisno o hijerarhijskim ovlaštenjima. Sustav je
                            koristan svakom pojedinom zaposleniku, a nadasve je jednostavan za
                            korištenje. Sustav se koristi trenutno, bez nabave posebne opreme i
                            softvera. Oglasna ploča, praćenje godišnjih odmora, uvid u aktualne projekte i
                            upravljanje službenim vozila, samo su neki od modula ove jedinstvene
                            aplikacije koja na jednom mjestu okuplja sve što je jednoj tvrtki potrebno
                            za bolje i pametnije praćenje poslovanja.
                        </p>
                    </div>

                    <div className={"d-md-flex flex-md-equal w-100 my-md-3 pl-md-3"}>
                        <div className="bg-dark shadow-sm"
                             style={{
                                 width: "100%",
                                 height: "250px",
                                 borderRadius: "21px 21px 0 0",
                                 backgroundImage: "url('https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80')",
                                 backgroundSize: "cover",
                                 backgroundPosition: "center",
                                 backgroundRepeat: "no-repeat"
                             }}>
                        </div>
                        <div className="text text-left">
                            <p>
                                 <Link to={"/uredski-pomocnik"} style={{color: "lightblue"}}><b>Kliknite ovdje i saznajte više</b></Link>
                                <br/>
                                Osim već postojećih modula (od
                                kojih tvrtka korisnik može odabrati koje želi koristiti, a koje ne), moguća je
                                i izrada personaliziranog modula napravljenog isključivo po željama i
                                potrebama tvrtke.
                                <br/>
                                Ako ste zainteresirani za prezentaciju ili demo sustav molimo kontaktirajte
                                nas na <a href="mailto:info@itcs.hr" style={{color: "lightblue"}}>info@itcs.hr</a>
                            </p>
                        </div>
                    </div>


                </div>
                <div class="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden" id={"constructis"}>
                    <div class="my-3 p-3">
                        <h2 class="display-5">Constructis</h2>
                        <p class="lead text-left">
                            Aplikacija za upravljanje gradilištima koja omogućava olakšano upravljanje
                            svim gradilištima jedne tvrtke. Sustav se koristi odmah, bez nabave
                            posebne opreme i softvera. Sustav je hijerarhijski posložen, te svaka
                            razina korisnika (direktor, voditelj gradilišta i radnik) ima personalizirani
                            prikaz sustava prilagođen njegovim potrebama i mogućnostima.
                        </p>


                    </div>
                    <div className={"d-md-flex flex-md-equal w-100 my-md-3 pl-md-3"}>
                        <div className="text text-left">
                            <p className={"text-dark"}>
                                Svrha ove
                                aplikacije je olakšan i digitalizirani uvid u stanje na svim gradilištima što
                                uključuje pregled stanja materijala, broj radnika i utrošenih sati te je
                                omogućeno i svakodnevno podnošenje izvještaja od strane radnika s
                                priloženim fotografijama napravljenog.
                                <br/>
                                Ako ste zainteresirani za prezentaciju ili demo sustav molimo kontaktirajte
                                nas na <a href="mailto:info@itcs.hr" style={{color: "blue"}}>info@itcs.hr</a>
                            </p>
                        </div>
                        <div className="bg-dark shadow-sm ml-auto"
                             style={{
                                 width: "" +
                                     "100%",
                                 height: "250px",
                                 borderRadius: "21px 21px 0 0",
                                 backgroundImage: "url('https://images.unsplash.com/photo-1485083269755-a7b559a4fe5e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80')",
                                 backgroundSize: "cover",
                                 backgroundPosition: "center",
                                 backgroundRepeat: "no-repeat"
                             }}>
                        </div>

                    </div>
                </div>
            </div>

            <div class="" >
                <div class="bg-warning mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden" id={"audio"}>
                    <div class="my-3 p-3">
                        <h2 class="display-5">Audio player</h2>
                        <p class="lead text-left">Za sve velike i male portale koji svoj sadržaj žele podignuti na višu razinu,
                            Audio player omogućava pretvorbu pisanih članaka u govor. Prednost ove aplikacije je što ne
                            zahtjeva ljudski glas već automatski prebacuje pisani tekst u govor bez ljudskog
                            posredovanja. </p>
                    </div>

                    <div className={"d-md-flex flex-md-equal w-100 my-md-3 pl-md-3"}>
                        <div className="bg-dark shadow-sm"
                             style={{
                                 width: "100%",
                                 height: "250px",
                                 borderRadius: "21px 21px 0 0",
                                 backgroundImage: "url('https://images.unsplash.com/photo-1516280440614-37939bbacd81?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80')",
                                 backgroundSize: "cover",
                                 backgroundPosition: "center",
                                 backgroundRepeat: "no-repeat"
                             }}>
                        </div>
                        <div className="text text-left">
                            <p className={"text-dark"}>Sustav podržava više od 50 jezika te njihove varijacije (npr.
                                britanski engleski, američki engleski itd.). Također, sustav pruža mogućnost odabira
                                neutralnog glasa čitatelja (ženski, muški, dječji). Postoji mogućnost odabira
                                personaliziranog glasa, napravljenog po željama korisnika.
                                <br/>
                                Ako ste zainteresirani za prezentaciju ili demo sustav molimo kontaktirajte
                                nas na <a href="mailto:info@itcs.hr" style={{color: "blue"}}>info@itcs.hr</a>
                            </p>
                        </div>
                    </div>

                </div>
                <div class="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden" id={"udruge"}>
                    <div class="my-3 py-3">
                        <h2 class="display-5 text-dark">Udruge</h2>
                        <p class="lead text-left">Moja Udruga je servis koji pomaže korisnicima (npr. predsjednicima i
                            tajnicima udruga) pri administratorskom dijelu posla vezanog za udruge.</p>
                    </div>
                    <div className={"d-md-flex flex-md-equal w-100 my-md-3 pl-md-3"}>
                        <div className="text text-left">
                            <p className={"text-dark"}>Neke od funkcionalnosti su vođenje evidencije o članovima,
                                zdravstvenim
                                pregledima, članarinama i sl. Sustav nudimo na korištenje svim udrugama
                                registriranim u RH bez ikakve naknade.
                                <br/>
                                Registrirajte se na <a href="https://www.udruga.online/" target={"_blank"}
                                                       style={{color: "blue"}}>https://www.udruga.online/</a>
                            </p>
                        </div>
                        <div className="bg-dark shadow-sm ml-auto"
                             style={{
                                 width: "100%",
                                 height: "250px",
                                 borderRadius: "21px 21px 0 0",
                                 backgroundImage: "url('https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80')",
                                 backgroundSize: "cover",
                                 backgroundPosition: "center",
                                 backgroundRepeat: "no-repeat"
                             }}>
                        </div>

                    </div>

                </div>
            </div>

            <footer class="container py-5" style={{backgroundColor: "white"}}>
                <div class="row">
                    <div class="col-12 col-md">
                        <i class="fab fa-apple" style={{fontSize: "3px"}}></i>
                        <small class="d-block mb-3 text-muted">&copy; 2022-2023</small>
                    </div>
                    <div class="col-6 col-md">
                        <h5>Tvrtka</h5>
                        <ul class="list-unstyled text-small">
                            <li><a class="text-muted" href="#">O nama</a></li>
                            <li><a class="text-muted" href="#">Proizvodi</a></li>
                            <li><a class="text-muted" href="#">Kontakt</a></li>
                        </ul>
                    </div>
                    <div class="col-6 col-md">
                        <h5>Usluge</h5>
                        <ul class="list-unstyled text-small">
                            <li><a class="text-muted" href="#">Programiranje</a></li>
                            <li><a class="text-muted" href="#">Dizajn</a></li>
                            <li><a class="text-muted" href="#">Savjetovanje</a></li>
                        </ul>
                    </div>
                </div>
            </footer>

        </div>
    )
}