export default function Privacy() {
    return (
        <>
            <div className="container" style={{maxWidth: "1000px"}}>
                <h1 style={{fontSize: "2.5rem", marginTop: "20px"}}><b>Privacy Policy</b></h1>
                <br/>
                <p style={{color: "black"}}>Last updated: January 03, 2024</p>
                <br/>
                <p style={{color: "black"}}>This Privacy Policy describes Our policies and procedures on the collection,
                    use and disclosure of Your information when You use the Service and tells
                    You about Your privacy rights and how the law protects You.</p>
                <br/>
                <p style={{color: "black"}}>We use Your Personal data to provide and improve the Service. By using the
                    Service, You agree to the collection and use of information in accordance
                    with this Privacy Policy.</p>
                <br/>
                <br/>
                <h3 style={{fontSize: "1.7rem", color: "darkblue"}}><b>Access </b></h3>
                <br/>
                <p style={{color: "black"}}>To access our application user must be logged in with the credentials that
                    he
                    got while signing up on his personal computer, then he scans the QR code
                    and logs in via the application.</p>
                <br/>
                <br/>
                <h3 style={{fontSize: "1.7rem", color: "darkblue"}}><b>Rating </b></h3>
                <br/>
                <p style={{color: "black"}}>Users can rate our app with a wanted grade on the Google Play Store/App
                    Store</p>
                <br/>
                <br/>
                <h3 style={{fontSize: "1.7rem", color: "darkblue"}}><b>Collecting and Using Your Personal Data</b></h3>
                <br/>
                <h4 style={{fontSize: "1.5rem", color: "darkblue"}}><b>Types of Data Collected</b></h4>
                <br/>
                <h4 style={{fontSize: "1.5rem", color: "darkblue"}}><b>Personal Data</b></h4>
                <br/>
                <p style={{color: "black"}}>While using Our Service, We may ask You to provide Us with certain
                    personally identifiable information that can be used to contact or identify
                    You. Personally identifiable information may include, but is not limited to:
                    <ul>
                        <li>Email address</li>
                        <li>First name and last name</li>
                        <li>
                            Phone number
                        </li>
                        <li>Address, State, Province, ZIP/Postal code, City</li>
                        <li>Usage Data</li>
                    </ul>
                </p>
                <br/>
                <br/>
                <h4 style={{fontSize: "1.5rem", color: "darkblue"}}><b>Usage Data</b></h4>
                <br/>
                <p style={{color: "black"}}>Usage Data is collected automatically when using the Service.
                    <br/>
                    Usage Data may include information such as Your Device's Internet Protocol
                    address (e.g. IP address), browser type, browser version, the pages of our
                    Service that You visit, the time and date of Your visit, the time spent on
                    those pages, unique device identifiers and other diagnostic data.
                    <br/>
                    When You access the Service by or through a mobile device, We may collect
                    certain information automatically, including, but not limited to, the type of
                    mobile device You use, Your mobile device unique ID, the IP address of Your
                    mobile device, Your mobile operating system, the type of mobile Internet
                    browser You use, unique device identifiers and other diagnostic data.
                    <br/>
                    We may also collect information that Your browser sends whenever You visit
                    our Service or when You access the Service by or through a mobile device.
                </p>
                <br/>
                <br/>
                <h4 style={{fontSize: "1.5rem", color: "darkblue"}}><b>Use of Your Personal Data</b></h4>
                <br/>
                <p style={{color: "black"}}>The Company may use Personal Data for the following purposes:
                    <ul>
                        <li><b>To provide and maintain our Service</b>, including to monitor the
                            usage of our Service.
                        </li>
                        <li><b>To manage Your Account:</b> to manage Your registration as a user of
                            the Service. The Personal Data You provide can give You access to
                            different functionalities of the Service that are available to You as a
                            registered user.
                        </li>
                        <li>
                            <b>To contact You:</b> To contact You by email, telephone calls, SMS, or
                            other equivalent forms of electronic communication, such as a mobile
                            application's push notifications regarding updates or informative
                            communications related to the functionalities, products or contracted
                            services, including the security updates, when necessary or
                            reasonable for their implementation
                        </li>
                        <li><b>To provide You</b> with news, special offers and general information
                            about other goods, services and events which we offer that are similar
                            to those that you have already purchased or enquired about unless
                            You have opted not to receive such information
                        </li>
                        <li><b>To manage Your requests:</b> To attend and manage Your requests to
                            Us.
                        </li>
                        <li><b>For business transfers:</b> We may use Your information to evaluate or
                            conduct a merger, divestiture, restructuring, reorganization,
                            dissolution, or other sale or transfer of some or all of Our assets,
                            whether as a going concern or as part of bankruptcy, liquidation, or
                            similar proceeding, in which Personal Data held by Us about our
                            Service users is among the assets transferred.
                        </li>
                        <li><b>For other purposes:</b> We may use Your information for other
                            purposes, such as data analysis, identifying usage trends, determining
                            the effectiveness of our promotional campaigns and to evaluate and
                            improve our Service, products, services, marketing and your
                            experience
                        </li>
                    </ul>
                </p>
                <br/>
                <br/>
                <h4 style={{fontSize: "1.5rem", color: "darkblue"}}><b>Retention of Your Personal Data</b></h4>
                <br/>
                <p style={{color: "black"}}>The Company will retain Your Personal Data only for as long as is
                    necessary
                    for the purposes set out in this Privacy Policy. We will retain and use Your
                    Personal Data to the extent necessary to comply with our legal obligations
                    (for example, if we are required to retain your data to comply with
                    applicable laws), resolve disputes, and enforce our legal agreements and
                    policies.
                    <br/>
                    The Company will also retain Usage Data for internal analysis purposes.
                    Usage Data is generally retained for a shorter period of time, except when
                    this data is used to strengthen the security or to improve the functionality of
                    Our Service, or We are legally obligated to retain this data for longer time
                    periods.
                </p>
                <br/>
                <br/>
                <h4 style={{fontSize: "1.5rem", color: "darkblue"}}><b>Transfer of Your Personal Data</b></h4>
                <br/>
                <p style={{color: "black"}}>Your information, including Personal Data, is processed at the Company's
                    operating offices and in any other places where the parties involved in the
                    processing are located. It means that this information may be transferred to
                    — and maintained on — computers located outside of Your state, province,
                    country or other governmental jurisdiction where the data protection laws
                    may differ than those from Your jurisdiction.
                    <br/>
                    Your consent to this Privacy Policy followed by Your submission of such
                    information represents Your agreement to that transfer.
                    <br/>
                    The Company will take all steps reasonably necessary to ensure that Your
                    data is treated securely and in accordance with this Privacy Policy and no
                    transfer of Your Personal Data will take place to an organization or a
                    country unless there are adequate controls in place including the security of
                    Your data and other personal information.
                </p>
                <br/>
                <br/>
                <h4 style={{fontSize: "1.5rem", color: "darkblue"}}><b>Delete Your Personal Data</b></h4>
                <br/>
                <p style={{color: "black"}}>You have the right to delete or request that We assist in deleting
                    the
                    Personal Data that We have collected about You.
                    <br/>
                    Our Service may give You the ability to delete certain information about You
                    from within the Service.
                    <br/>
                    You may update, amend, or delete Your information at any time by signing in
                    to Your Account, if you have one, and visiting the account settings section
                    that allows you to manage Your personal information. You may also contact
                    Us to request access to, correct, or delete any personal information that You
                    have provided to Us.
                    <br/>
                    Please note, however, that We may need to retain certain information when
                    we have a legal obligation or lawful basis to do so.
                </p>
                <br/>
                <br/>
                <h3 style={{fontSize: "1.7rem", color: "darkblue"}}><b>Disclosure of Your Personal Data</b></h3>
                <br/>
                <h4 style={{fontSize: "1.5rem", color: "darkblue"}}><b>Business Transactions</b></h4>
                <br/>
                <p style={{color: "black"}}>If the Company is involved in a merger, acquisition or asset sale, Your
                    Personal Data may be transferred. We will provide notice before Your
                    Personal Data is transferred and becomes subject to a different Privacy
                    Policy.
                </p>
                <br/>
                <br/>
                <h4 style={{fontSize: "1.5rem", color: "darkblue"}}><b>Law enforcement</b></h4>
                <br/>
                <p style={{color: "black"}}>Under certain circumstances, the Company may be required to disclose Your
                    Personal Data if required to do so by law or in response to valid requests by
                    public authorities (e.g. a court or a government agency)
                </p>
                <br/>
                <br/>
                <h4 style={{fontSize: "1.5rem", color: "darkblue"}}><b>Other legal requirements</b></h4>
                <br/>
                <p style={{color: "black"}}>The Company may disclose Your Personal Data in the good faith belief that
                    such action is necessary to:
                </p>
                <ul>
                    <li>Comply with a legal obligation</li>
                    <li>Protect and defend the rights or property of the Company</li>
                    <li>Prevent or investigate possible wrongdoing in connection with the
                        Service
                    </li>
                    <li>Protect the personal safety of Users of the Service or the public</li>
                    <li>Protect against legal liability</li>
                </ul>
                <br/>
                <br/>
                <h4 style={{fontSize: "1.5rem", color: "darkblue"}}><b>Security of Your Personal Data</b></h4>
                <br/>
                <p style={{color: "black"}}>The security of Your Personal Data is important to Us, but remember that no
                    method of transmission over the Internet, or method of electronic storage is
                    100% secure. While We strive to use commercially acceptable means to
                    protect Your Personal Data, We cannot guarantee its absolute security.
                    <br/>
                    Our Service may contain links to other websites that are not operated by Us.
                    If You click on a third party link, You will be directed to that third party's
                    site. We strongly advise You to review the Privacy Policy of every site You
                    visit.
                    <br/>
                    We have no control over and assume no responsibility for the content,
                    privacy policies or practices of any third party sites or services.
                </p>
                <br/>
                <br/>
                <h3 style={{fontSize: "1.7rem", color: "darkblue"}}><b>Advertisement</b></h3>
                <br/>
                <p style={{color: "black"}}>Our app does not use advertisements.
                </p>
                <br/>
                <br/>
                <h3 style={{fontSize: "1.7rem", color: "darkblue"}}><b>Target audience</b></h3>
                <br/>
                <p style={{color: "black"}}>Our app is targeted toward an 18+ audience.
                </p>
                <br/>
                <br/>
                <h3 style={{fontSize: "1.7rem", color: "darkblue"}}><b>Changes to this Privacy Policy</b></h3>
                <br/>
                <p style={{color: "black"}}>We may update Our Privacy Policy from time to time. We will notify You of
                    any changes by posting the new Privacy Policy on this page.
                    <br/>
                    We will let You know via email and/or a prominent notice on Our Service,
                    prior to the change becoming effective and update the "Last updated" date
                    at the top of this Privacy Policy.
                    <br/>
                    You are advised to review this Privacy Policy periodically for any changes.
                    Changes to this Privacy Policy are effective when they are posted on this
                    page.
                </p>
                <br/>
                <br/>
                <h3 style={{fontSize: "1.7rem", color: "darkblue"}}><b>Contact Us</b></h3>
                <br/>
                <p style={{color: "black"}}>If you have any questions about this Privacy Policy, You can contact us:
                </p>
                <ul style={{marginBottom: "40px"}}>
                    <li>By email: vanja@itcs.hr</li>
                    <li>By phone number: +385 911101507</li>
                </ul>
            </div>
        </>
    )
}