import React from "react";
import logoLight from './../img/logo-white.png'
import eng from './../img/english.png'
import {Link} from "react-router-dom";
import jumbotron from './../img/jumbotron.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleDown, faArrowDown, faBars} from "@fortawesome/free-solid-svg-icons";
import design from './../img/web-design-itcs.png'
import development from './../img/web-app.png'
import ChristmasTree from "./ChristmasTree";
import Navbar from "./Navbar";


export default function Hr() {
    return (
        <div className={"main-div"}>
            <input type="checkbox" id="nav-toggler" style={{display: "none"}}/>
            <div className="nav-bar" id="navbar" style={{padding: "10px"}}>
                <h1 className="web-title">
                    <div>
                        <div>
                            <span><img src={logoLight} width="80" alt=""/></span>
                        </div>
                    </div>
                </h1>
                <label htmlFor="nav-toggler" style={{display: "flex", alignItems: "center"}}>
                    <Link to={'/en'}><img src={eng} width="50" style={{padding: "5px"}} alt=""/></Link>
                    <FontAwesomeIcon icon={faBars} style={{color: 'gold'}}/>
                </label>
            </div>
            <div className="side-bar" data-open="false">
                <ul className="navbar-options">
                    <li className="navbar-item"><a href="#design">Dizajn</a></li>
                    <li className="navbar-item"><a href="#development">Programiranje</a></li>
                    <li className="navbar-item"><a href="#consulting">Savjetovanje</a></li>
                    <li className="navbar-item"><a href="/products">Proizvodi</a></li>
                    <li className="navbar-item"><a href="#" data-toggle="modal" data-target="#exampleModal">Kontakt</a>
                    </li>
                    <li className="navbar-item"><a href="/uredski-pomocnik">Uredski pomoćnik</a>
                    </li>
                    <li className="navbar-item">
                        <hr style={{border: 'none', borderBottom: '2px solid #E4D1B0'}}/>
                    </li>
                    <li className="navbar-item" id="company">
                        <h6>IT Consulting Services d.o.o.</h6>
                        <p>Stjepana Radića 18</p>
                        <p>HR-47000 Karlovac</p>
                        <p>Trgovačko društvo upisano je u Sudski registar Trgovačkog suda u Zagrebu (SS Karlovac) pod
                            matičnim
                            brojem subjekta 060141866</p>
                        <p>Temeljni kapital: 36.259,87 euro, uplaćen u cijelosti.</p>
                        <p>IBAN: HR2124840081101323324</p>
                        <p>kod Raiffeisenbank Austria d.d.</p>
                        <p>OIB: 42237961602</p>
                        <p>Član uprave: Branko Radojević</p>
                        <p>+385 91 128 1064</p>
                    </li>
                </ul>
            </div>

            <section className="front-main">

                <img src={jumbotron} alt="front-icon"/>

                <div className="main-header-details">
                    <p>Connecting your dots</p>
                    <h1>IT Consulting Services</h1>
                    <p>
                        Stvaramo kompleksne web aplikacije i unikatne web stranice u skladu s potrebama naših
                        korisnika
                    </p>

                    <p> Pružamo stručne konzultantske usluge u području ITC-a</p>
                    <p style={{color: "gold"}}>Ozbiljni poduzetnici zaslužuju ozbiljne partnere!
                    </p>
                    <div className="row">

                        <button className="btn btn-outline-warning ml-2" data-toggle="modal"
                                data-target="#exampleModal">
                            KONTAKTIRAJTE NAS
                        </button>
                    </div>
                </div>


                <div className="arrow-test">
                     <a href="#development">
                        <FontAwesomeIcon icon={faAngleDoubleDown} bounce style={{color: 'gold'}} className={'bounce'}/>
                    </a>
                </div>


            </section>


            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel">Obratite nam se s povjerenjem!</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5>IT Consulting Services</h5>
                            <a href="mailto:info@itcs.hr">Email: info@itcs.hr</a>
                            <br/>
                            <a href="phone:+385911281064">Telefon: +385 91 128 1064</a>


                        </div>
                    </div>
                </div>
            </div>


            <div id="development" style={{marginBottom: "5%"}}></div>
            <div className="content-section-b">


                <div className="row content-b">
                    <div className="col-lg-5">


                        <h2 className="section-heading">1. PROGRAMIRANJE WEB APLIKACIJA </h2>
                        <hr className="section-heading-spacer"/>
                        <div className="clearfix"></div>

                        <p className="lead">

                            U skladu s potrebama korisnika izrađujemo kompleksne web aplikacije koje olakšavaju
                            poslovanje
                            korisnika. Posjedujemo višegodišnje iskustvo u izradi aplikacija kao i kvalitetan tim
                            koji ideje
                            korisnika pretvara u stvarnost. U suradnji s korisnicima realiziramo najbolja rješenja
                            za
                            automatizaciju poslovnih procesa. Koristimo programski jezik za backend – Python, web
                            framework
                            Django i frontend framework REACT.

                            Izrađujemo suvremene web stranice po zahtjevu korisnika za sve tri platforme (mobitele,
                            tablete i računala) bazirane na tehnologijama React za frontend i Django za backend te
                            ne koristimo
                            CMS sustave poput Joomle ili Word Press-a. Upotrebljavamo navedene tehnologije jer
                            omogućavaju
                            kvalitetniju prilagodbu sustava potrebama korisnika i nisu ograničene formama koje
                            diktiraju CMS
                            sustavi.
                        </p>
                        <a href="/products">Pogledajte neke od naših proizvoda</a>
                    </div>
                    <div className="col-lg-5 col-lg-offset-2 col-sm-6" align="center">

                        <div align="center">
                            <img className="img-responsive" src={design}
                                 alt=""/>
                        </div>
                    </div>
                </div>
            </div>


            <div class="content-section-d">
                <div id="design" style={{marginBottom: "5%"}}></div>


                <div class="row content-c">
                    <div class="col-lg-5 col-lg-offset-2 col-sm-6" align="center">
                        <div class="div" align="center">
                            <img class="phone"
                                 src={development} alt=""/>

                        </div>
                    </div>

                    <div class="col-lg-5">

                        <h2 class="section-heading-white text-dark">2. DIZAJN I IZRADA WEB STRANICA</h2>

                        <hr class="section-heading-spacer-white"/>
                        <div class="clearfix"></div>

                        <p class="lead">
                            Izrađujemo suvremene web stranice po zahtjevu korisnika za sve tri platforme (mobitele,
                            tablete i računala) bazirane na tehnologijama React za frontend i Django za backend te ne
                            koristimo
                            CMS sustave poput Joomle ili Word Press-a. Upotrebljavamo navedene tehnologije jer
                            omogućavaju
                            kvalitetniju prilagodbu sustava potrebama korisnika i nisu ograničene formama koje diktiraju
                            CMS
                            sustavi.
                        </p>
                        <p class="lead">Klijentima pružamo usluge dizajna i izrade web stanica.</p>
                        <p class="lead">Za stranice koje kreiramo nudimo i registraciju domena, web hosting, kreiranje
                            sadržaja,
                            održavanje, sigurnosne kopije te ostale potrebne usluge.</p>
                        <p class="lead">
                            Za više informacija posjetite stranicu <a href="https://hocu.website/">hocu.website</a>.
                        </p>

                    </div>

                </div>


            </div>


            <div className="content-section-a" id="consulting">
                <div style={{marginBottom: "5%"}}></div>
                <div className="row content-a">
                    <div className="col-lg-12 col-lg-offset-1">

                        <div className="clearfix"></div>
                        <h2 className="section-heading">3. SAVJETOVANJE</h2>
                        <hr className="section-heading-spacer"/>
                        <div className="clearfix"></div>
                        <h4>Analiza i revizija</h4>
                        <p className="lead">Kvalitetno izrađena dokumentacija nužna je za učinkovito upravljanje
                            resursima te
                            čini temelj za ikakvu
                            daljnju nadogradnju sustava.
                        </p>
                        <p className="lead">Naš tim stručnjaka pomoći će vam detektirati postojeće stanje i
                            savjetovati vas o
                            uspostavi kvalitetne
                            infrastrukturne osnove koja je neophodna u svakoj uspješnoj organizaciji.
                        </p>
                        <h4 className="">Migracije</h4>
                        <p className="lead">Ispravna procjena oko ulaganja u vlastiti prostor za opremu,
                            infrastrukturu,
                            servise ili korištenje
                            iznajmljenih resursa u zemlji ili inozemstvu, a da se pri tom ne narušava sigurnosti
                            sustava,
                            ozbiljna
                            je stavka za proračun svake organizacije i radi toga joj treba vrlo stručno pristupiti.
                            Nudimo vam
                            usluge oko savjetovanja oko migracija sustava na unajmljeni prostor, infrastrukturu,
                            oblak ili
                            servise.
                        </p>

                        <h4 className="">Nabava IT opreme i usluga</h4>
                        <p className="lead">Mala greška kod definiranja nabave može rezultirati višemjesečnim
                            odgađanjem
                            isporuke i nepotrebnim
                            dodatnim investicijama. Savjetujte se na vrijeme, dobro planirajte.

                        </p>

                        <h4 className="">Nadzor</h4>
                        <p className="lead">
                            Osigurajte se da dobijete kroz isporuke upravo ono što ste ugovorom i tražili.
                            Obratite nam se s povjerenjem jer naši savjetnici imaju višegodišnje iskustvo na ovakvim
                            i sličnim
                            poslovima. Ako niste sigurni u usluge migracije i konsolidacije, povjerite posao
                            iskusnim
                            savjetnicima
                            koji će vas pratiti kroz sve dogovorene faze migracije i/ili konsolidacije vaše
                            infrastrukture i
                            informacijskih servisa.

                        </p>


                    </div>
                </div>

            </div>
            <footer style={{backgroundColor: "black"}}>

                <div className="row">

                    <p className="copyright text-white small ml-5">Copyright &copy; IT Consulting Services d.o.o.</p>

                </div>

            </footer>

        </div>
    )
}