import logo from './logo.svg';
import './scss/styles.css';
import {Routes} from "react-router-dom";
import {Route} from "react-router-dom";
import Hr from "./Components/Hr";
import En from "./Components/En";
import Products from "./Components/Products";
import ProductsEng from "./Components/ProductsEng";
import Main from "./Components/OfficeHelper/Main";
import "./App.css"
import Privacy from "./Components/Privacy";

function App() {
    return (
        <div className="App">

            <Routes>
                <Route exact path="/" element={<Hr/>}/>
                <Route exact path="/en" element={<En/>}/>
                <Route exact path="/products" element={<Products/>}/>
                <Route exact path="/products/eng" element={<ProductsEng/>}/>
                <Route exact path="/uredski-pomocnik" element={<Main/>}/>
                <Route exact path="/itcs-oa/privacy_policy" element={<Privacy/>}/>
            </Routes>
        </div>
    );
}

export default App;
