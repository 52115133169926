import React from "react";
import {Link} from "react-router-dom";


export default function ProductsEng() {
    return (
        <>
            <nav class="site-header sticky-top py-1 bg-light">
                <div class="container d-flex flex-column flex-md-row justify-content-between">
                    <a class="py-2" href="#">
                    </a>
                    <a class="py-2 d-none d-md-inline-block" href="/">Home</a>
                    <a class="py-2 d-none d-md-inline-block" href="#inp">Office assistant</a>
                    <a class="py-2 d-none d-md-inline-block" href="#constructis">Constructis</a>
                    <a class="py-2 d-none d-md-inline-block" href="#audio">Audio player</a>
                    <a class="py-2 d-none d-md-inline-block" href="#udruge">Associations</a>
                </div>
            </nav>

            <div class="position-relative overflow-hidden text-center bg-light">
                <div class="col-md-5 p-lg-5 mx-auto my-5">
                    <h1 class="display-4 font-weight-normal">Products</h1>
                    <p class="lead font-weight-normal">Take a look at the our products and choose the one that is
                        adapted to your needs.</p>
                    <a class="btn btn-outline-secondary" href="#inp">See more</a>
                </div>
                <div class="product-device shadow-sm d-none d-md-block"></div>
                <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
            </div>

            <div class="">
                <div class="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden"
                     id={"inp"}>
                    <div class="my-3 py-3">
                        <h2 class="display-5">Office assistant</h2>
                        <p class="lead text-light text-left">The company's internal system visible only to
                            company employees.
                            It enables management to have an easy insight into business and organizational processes and
                            offers different insight into content, depending on hierarchical authority. It's a system
                            useful for every single employee, and above all, it is easy to
                            use. The system is used immediately, without the purchase of special equipment and
                            software. Notice board, monitoring of annual vacations, insight into current projects and
                            management of official vehicles, are only some of the modules of this unique
                            applications that gather everything a company needs in one place
                            for better and smarter business monitoring.
                        </p>
                    </div>

                    <div className={"d-md-flex flex-md-equal w-100 my-md-3 pl-md-3"}>
                        <div className="bg-dark shadow-sm"
                             style={{
                                 width: "100%",
                                 height: "250px",
                                 borderRadius: "21px 21px 0 0",
                                 backgroundImage: "url('https://images.unsplash.com/photo-1497215728101-856f4ea42174?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80')",
                                 backgroundSize: "cover",
                                 backgroundPosition: "center",
                                 backgroundRepeat: "no-repeat"
                             }}>
                        </div>
                        <div className="text text-left">
                            <p>
                                <Link to={"/uredski-pomocnik"} style={{color: "lightblue"}}><b>Click here to see more</b></Link>
                                <br/>
                                In addition to already existing modules (from
                                which company the user can choose which he wants to use and which he doesn't), it is
                                possible
                                to create a personalized module made exclusively according to the wishes and
                                the needs of the company.
                                <br/>
                                If you are interested in a presentation or demo system please contact
                                us on <a href="mailto:info@itcs.hr" style={{color: "lightblue"}}>info@itcs.hr</a>
                            </p>
                        </div>
                    </div>


                </div>
                <div class="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden" id={"constructis"}>
                    <div class="my-3 p-3">
                        <h2 class="display-5">Constructis</h2>
                        <p class="lead text-left">
                            An application for the management of construction sites that enables easier management
                            to all construction sites of one company. The system is used immediately, without purchase
                            special equipment and software. The system is arranged hierarchically, and each
                            user level (director, site manager and worker) has personalized
                            display of the system adapted to its needs and capabilities.
                        </p>


                    </div>
                    <div className={"d-md-flex flex-md-equal w-100 my-md-3 pl-md-3"}>
                        <div className="text text-left">
                            <p className={"text-dark"}>

                                The purpose of this
                                the application is facilitated and digitized insight into the condition of all
                                construction sites which includes an overview of the state of materials, the number of
                                workers and hours spent, and is
                                enabled daily submission of reports by workers with
                                attached photos of the work done.
                                <br/>
                                If you are interested in a presentation or demo system please contact
                                us on <a href="mailto:info@itcs.hr" style={{color: "blue"}}>info@itcs.hr</a>
                            </p>
                        </div>
                        <div className="bg-dark shadow-sm ml-auto"
                             style={{
                                 width: "" +
                                     "100%",
                                 height: "250px",
                                 borderRadius: "21px 21px 0 0",
                                 backgroundImage: "url('https://images.unsplash.com/photo-1485083269755-a7b559a4fe5e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80')",
                                 backgroundSize: "cover",
                                 backgroundPosition: "center",
                                 backgroundRepeat: "no-repeat"
                             }}>
                        </div>

                    </div>
                </div>
            </div>

            <div class="">
                <div class="bg-warning mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden" id={"audio"}>
                    <div class="my-3 p-3">
                        <h2 class="display-5">Audio player</h2>
                        <p class="lead text-left">
                            For all large and small portals that want to raise their content to a higher level,
                            the audio player enables the conversion of written articles into speech. The advantage of
                            this application is that it does not
                            requires a human voice and automatically converts written text into speech without a human
                            mediation.</p>
                    </div>

                    <div className={"d-md-flex flex-md-equal w-100 my-md-3 pl-md-3"}>
                        <div className="bg-dark shadow-sm"
                             style={{
                                 width: "100%",
                                 height: "250px",
                                 borderRadius: "21px 21px 0 0",
                                 backgroundImage: "url('https://images.unsplash.com/photo-1516280440614-37939bbacd81?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80')",
                                 backgroundSize: "cover",
                                 backgroundPosition: "center",
                                 backgroundRepeat: "no-repeat"
                             }}>
                        </div>
                        <div className="text text-left">
                            <p className={"text-dark"}>
                                The system supports more than 50 languages and their variations (e.g.
                                British English, American English, etc.). Also, the system provides the possibility of
                                selection
                                neutral voice of the reader (female, male, child). There is a possibility to choose
                                personalized voice, made according to the wishes of the user.
                                <br/>
                                If you are interested in a presentation or demo system please contact
                                us on <a href="mailto:info@itcs.hr" style={{color: "blue"}}>info@itcs.hr</a>
                            </p>
                        </div>
                    </div>

                </div>
                <div class="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden"
                     id={"udruge"}>
                    <div class="my-3 py-3">
                        <h2 class="display-5 text-dark">Associations</h2>
                        <p class="lead text-left">
                            Moja Udruga is a service that helps users (e.g. presidents and
                            secretaries of associations) in the administrative part of work related to associations.</p>
                    </div>
                    <div className={"d-md-flex flex-md-equal w-100 my-md-3 pl-md-3"}>
                        <div className="text text-left">
                            <p className={"text-dark"}>Some of the functionalities are keeping records of members,
                                health
                                examinations, membership fees, etc. We offer the system for use by all associations
                                registered in the Republic of Croatia without any compensation.
                                <br/>
                                Register on <a href="https://www.udruga.online/" target={"_blank"}
                                                       style={{color: "blue"}}>https://www.udruga.online/</a>
                            </p>
                        </div>
                        <div className="bg-dark shadow-sm ml-auto"
                             style={{
                                 width: "100%",
                                 height: "250px",
                                 borderRadius: "21px 21px 0 0",
                                 backgroundImage: "url('https://images.unsplash.com/photo-1582213782179-e0d53f98f2ca?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80')",
                                 backgroundSize: "cover",
                                 backgroundPosition: "center",
                                 backgroundRepeat: "no-repeat"
                             }}>
                        </div>

                    </div>

                </div>
            </div>

            <footer class="container py-5" style={{backgroundColor: "white"}}>
                <div class="row">
                    <div class="col-12 col-md">
                        <i class="fab fa-apple" style={{fontSize: "3px"}}></i>
                        <small class="d-block mb-3 text-muted">&copy; 2022-2023</small>
                    </div>
                    <div class="col-6 col-md">
                        <h5>Company</h5>
                        <ul class="list-unstyled text-small">
                            <li><a class="text-muted" href="/">About</a></li>
                            <li><a class="text-muted" href="#inp">Products</a></li>
                            <li><a class="text-muted" href="/">Contact</a></li>
                        </ul>
                    </div>
                    <div class="col-6 col-md">
                        <h5>Services</h5>
                        <ul class="list-unstyled text-small">
                            <li><a class="text-muted" href="/">Programming</a></li>
                            <li><a class="text-muted" href="/">Design</a></li>
                            <li><a class="text-muted" href="/">Consulting</a></li>
                        </ul>
                    </div>
                </div>
            </footer>

        </>
    )
}